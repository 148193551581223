.person-container {
    padding-left: 100px;
    
}

.person-grid-container {
    padding: 40px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.video-editing-container {
    padding: 5px 40px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}