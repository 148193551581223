.video-details-container {
    height: 80vh;
    background-color: #00000070;
    border-radius: 5px;
    padding: 15px;
    overflow: auto;
    
}

.video-details-container:hover {
    overflow: auto;
}

.video-details-thumb {
    position: relative;
    background-color: #ffffff10;
    border-radius: 5px;
}

.video-details-thumb img {
    border-radius: 5px;
    max-height: 300px;
}


.video-details-thumb-error:hover img{
    opacity: 0.1;
}

.video-details-thumb-duration {
    position: absolute;
    right: 10px;
    bottom: 10px;
    border-radius: 5px;
    background-color: #21212190;
    padding: 2px 10px;
    font-size: 12px;
    font-family: GalanoGrotesqueBold;
    width: min-content;
}

.video-details-origin-thumbnail {
    width: 90%;
    height: 80px;
    margin-top: 8px;
}

.video-details-origin-thumbnail img{
    object-fit: cover;
    width: 100%;
    height: 65px;
    border-radius: 5px;
}

.video-details-data-content {
    width: auto;
    height: auto;
    font-size: 12px;
    font-family: GalanoGrotesque;
    border-radius: 5px;
    margin: 0px 0px 0px 5px;
    padding: 0px 0px 0px 5px;
    color: #FFFFFF90;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.video-details-data-content span{
    margin-left: 10px;
}

.video-details-metadata {
    width: 100%;
}


.video-details-metadata svg {
    position: relative;
    top: 7px;
    margin-right: 10px;
}

.video-details-metadata span {
    height: 25px;
}


.video-details-block {
    width: 100%;
    border-radius: 5px;
    background-color: #ffffff10;
    padding: 10px;
}

.video-details-actions {
    width: auto;
    height: auto;
    
    font-size: 14px;
    font-family: GalanoGrotesqueBold;
    margin: 20px 0px;
    border-radius: 5px;
    
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between; 
}

.video-detils-download-renditions {
    width: '100%'; 
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: #ffffff10;
    padding: 10px;
}