
.navigation {
    margin-top: 20px;
}


.avatar-disabled img {
    opacity: 0.2;
}

.tooltip-icons {
    flex-grow: 1;
    text-align: right;
}

.tooltip-icons svg{
    margin-left: 5px;
    color: #FFFFFF90;
    cursor: pointer;
}

@media (min-aspect-ratio: 1660/900) and (max-aspect-ratio: 1800/700){

    .navigation {
        margin-top: 0px;
    }
}