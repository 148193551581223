.controller-container {
    height: 30vh;
    width: 100%;
}

.controller-grid-container {
    height: 100%;
}

.slider-container {
    position: relative;
    height: 115px;
}

.zoom-container {
    position: relative;
    height: 115px;
    text-align: -webkit-center;
    padding-top: 10px;
}


.slider-ref-container {
    position: relative;
    height: 115px;
    width: 10px;
}

.circular-progress-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;   
}

.thumbs-container {
    position: absolute;
    height: 80px;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    margin-top: 35px;
    width: 100%;
}

.thumbs-item {
    flex-grow: 1;
    width: 100%;
}

.thumb {
    width: 100%;
    border-radius: 5px;
    padding: 1px;
}

.horizzontal-space {
    height: 10px;
    width: 100%;
}

@media (max-aspect-ratio: 1660/900) {

    .controller-container {
        height: 40vh;
        
    }
}


