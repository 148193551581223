.container {
    margin: 0;
    width: 100%;
    height: 100%;
}

.signin-space {
    height: 15vh;
}

.signin-container {
    height: 70vh;
    display: flex;
    flex-direction: row;
}


.left-side{
    width: 50%;
    height: 100%;
    background-color: white;
    border-radius: 25px 0px 0px 25px;
}


.right-side{
    width: 50%;
    height: 100%;
    background-color: #FF9666;
    border-radius: 0px 25px 25px 0px;
}

.welcome {
    color: #FF9666;
    font-family: GalanoGrotesqueLight;
    font-size: 24px;
    text-align: center;
    margin-top: 15%;
    height: auto;
}

.login-form-container {
    margin-top: 20%;
    width: 70%;
    margin-left: 15%;
}

.logo-login {
    margin: auto;
    height: 100%;
    width: 70%;
    text-align: center;
    display: block;

}

.link {
    text-align: right;
    float: right;
    font-family: GalanoGrotesqueLight;
    font-size: 13px;
}

.login-divider {
    background-color: #EB5E50;
    margin: 8% 0%;
}

.signup-phrase {
    color: #FF9666;
    font-family: GalanoGrotesqueLight;
    font-size: 13px;
    text-align: center;
    height: auto;
}

.signup-phrase-link {
    color: #FF9666;
    font-family: GalanoGrotesqueBold;
    font-size: 13px;
    padding-left: 5px;
}

@media (max-aspect-ratio: 1800/700) {

    
}


@media (min-aspect-ratio: 1660/900) and (max-aspect-ratio: 1800/700){

    .welcome {
        font-size: 20px;
        margin-top: 13%;
    }
    .login-form-container {
        margin-top: 13%;
        width: 70%;
        margin-left: 15%;
    }
}

@media (orientation: portrait) {
    .right-side {
        display: none;
    }

    .left-side{
        width: 100%;
        border-radius: 25px 25px;
    }

    .welcome {
        font-size: 12px;
    }

    .login-form-container {
        width: 90%;
        margin: auto;
        margin-bottom: 15%;
    }

    .signin-container {
        height: auto;
        display: flex;
        flex-direction: row;
    }

    span.left-side > div.welcome > h1::after {
        content: ' ao Crabber'
    }

    .MuiInputAdornment-root {
        display: none !important;
    }
    
}
