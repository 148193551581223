.player-control-controls {
    width: 99%;
    display: flex;
    margin-top: 8px;
    justify-content: space-between;
    justify-content: space-between;
    background-color: #00000020;
    border-radius: 5px;
    height: 78px;
 }

 .player-control-video-sub-controls {
    display: flex;
    margin-left: 5px;
    margin-right: 15px;
}

.player-control-videosub-controls-fake {
    width: 22vh;
}

.player-control-player-icon {
    width: 40px;
}

.player-control-player-live-icon {
    height: 32px;
    width: 19.8vh;
    align-self: center;
}
