.usage-container {
    height: 15vh;
    width: 100%;
}

.blink {
    animation: blinker 1.0s cubic-bezier(.5, 0, 1, 1) infinite alternate;
    color: red; 
    margin-right: 8px;
    vertical-align: bottom;
}

.blink-history {
    animation: blinker 1.0s cubic-bezier(.5, 0, 1, 1) infinite alternate;
    color: red; 
    margin-right: 6px;
    margin-top: 0px;
    vertical-align: bottom;
}

@keyframes blinker {  
    from { opacity: 1; }
    to { opacity: 0; }
}

.not-blink {
    display: none; 
    
}


.timer h6 {
    display: inline;
}

.usage-grid-container {
    margin-top: 20px;
    margin-left: 20px;
    max-width: 100%;
}
