.video-library-container {
    height: 80vh;
    width: 97%;
    background-color: #00000070;
    border-radius: 5px;
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    
}

.video-library-container:hover {
    overflow-y: auto;
    overflow-x: hidden;
}

.video-item-container {
    width: 500px;
    background-color: #00000030;
    border-radius: 5px;
    padding: 15px;
    margin: 10px;
    color: #FFFFFF90;
}

.video-item-title {
    margin: 5px 0px;
}

.video-item-thumb {
    width: 138px;
    height: 78px;
    text-align: center;
}

.video-item-thumb img {
    max-width: 150px;
    max-height: 78px;
    width: auto;
    height: auto;
    border-radius: 5px;
    cursor: pointer;
}

.video-item-thumb-duration {
    position: absolute;
    right: 10px;
    bottom: 10px;
    border-radius: 5px;
    background-color: #21212190;
    padding: 2px 10px;
    font-size: 12px;
    font-family: GalanoGrotesqueBold;
    width: min-content;
}

.video-item-thumb-status {
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%, -50%); 
    border-radius: 5px;
    background-color: #21212190;
    padding: 2px 10px;
    font-size: 12px;
    font-family: GalanoGrotesqueBold;
    width: auto;
}

.video-item-metadata {
    width: 100%;
    padding: 0px 15px 20px 15px;
}


.video-item-metadata svg {
    position: relative;
    top: 7px;
    margin-right: 10px;
}

.video-item-metadata span {
    height: 25px;
}

.video-item-origin-container {
    background-color: #00000030;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    height: 100%;
}

.video-item-origin-thumbnail {
    max-width: 120px;
}

.video-item-origin-thumbnail img{
    object-fit: cover;
    width: 100%;
    height: 65px;
    border-radius: 5px;
}
