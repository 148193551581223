.social-container {
    padding-left: 100px;
    overflow: hidden;
}


.social-grid-container{
    padding: 40px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.horizzontal-spacing {
    height: 20px;
}

.account-container {
    width: 100%;
    display: contents;
}

.social-block {
    background-color: #ffffff10;
    border-radius: 5px;
    width: 100%;
    padding: 20px;
    margin-bottom: 30px;
}