.clips-container {
    height: 55vh;
    width: 100%;
    background-color: #00000070;
    border-radius: 5px;
    padding: 15px;
    overflow: auto;
    
}

.clips-container:hover {
    overflow: auto;
}

.clips-title {
    margin-bottom: 30px;
    margin-left: 5px;
}

.clip-container {
    /*height: 50px;*/
    width: auto;
    padding: 0px 10px 10px 10px;
    margin: 10px 0px;
    background-color: #00000070;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.clip-thumb {
    position: relative;
    width: 120px;
    height: 68px;
    margin-top: 16px;
}

.clip-thumb img {
    border-radius: 5px;
    width: 120px;
    cursor: pointer;
}

.clip-thumb-duration {
    position: absolute;
    right: 5px;
    bottom: 5px;
    border-radius: 5px;
    background-color: #21212190;
    padding: 2px 10px;
    font-size: 12px;
    font-family: GalanoGrotesqueBold;

}


.clip-data-container {
    position: relative;
    width: 100%;
    
}


.clip-data-title {
    width: auto;
    height: 68px;
    font-size: 14px;
    font-family: GalanoGrotesqueBold;
    margin: 0px 0px 0px 5px;
    padding: 0px 0px 0px 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}

.clip-data-title-text {
    flex-grow: 10;
}




.clip-data-content {
    width: auto;
    height: auto;
    font-size: 12px;
    font-family: GalanoGrotesque;
    border-radius: 5px;
    margin: 0px 0px 0px 5px;
    padding: 0px 0px 0px 5px;
    color: #FFFFFF90;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.clip-data-content span{
    margin-left: 10px;
}

.clip-data-block {
    width: auto;
    height: auto;
    background-color: #FFFFFF10;
    font-size: 14px;
    font-family: GalanoGrotesqueBold;
    margin: 10px;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between; 
}

.clip-data-player {
    width: auto;
    height: auto;
    
    font-size: 14px;
    font-family: GalanoGrotesqueBold;
    margin: 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between; 
}

.clip-data-player div{
    width: 100%;
}

.clip-data-player video{
    width: 100%;
    border-radius: 10px;
}


.clip-data-actions {
    width: auto;
    height: auto;
    
    font-size: 14px;
    font-family: GalanoGrotesqueBold;
    margin: 20px 10px;
    border-radius: 5px;
    
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    /* justify-content: space-between;  */
    gap: 10px;
}

.clip-data-block-item {
    margin-bottom: 15px;
    width: 100%;
}

.clip-data-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
}

.clip-data-title-icons {
    flex-grow: 2;
    text-align: right;
}

.clip-data-title-icons svg{
    margin-left: 5px;
    color: #FFFFFF90;
    cursor: pointer;
}

.clip-data-message {
    
    margin-left: 10px;
}

@media (max-aspect-ratio: 1660/900) {

    .clips-container {
        height: 45vh;
        
    }
}

@media (max-aspect-ratio: 1280/800) {

    .clips-container {
        height: 45vh;
    }

    .clip-data-title {
        height: 100px;
    }
}