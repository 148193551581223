.container {
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.signup-grid-container {
    margin-top: 1%;
}
.signup-logo {
    width: 40%;
    margin: auto;
    text-align: center;
    display: block;
}

.signup-reset-button {
    float: right;
    text-align: right;
    margin-top: 10px;
    margin-right: 10px;
    padding: 10px;
}

.signup-spacing {
    margin-top: 10px;
}