.change-password-grid-container {
    margin-top: 5%;
}

.change-password-reset-button {
    float: right;
    text-align: right;
    margin-top: 10px;
}

.change-password-spacing {
    margin-top: 10px;
}