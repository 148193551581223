.player-container {
    height: 100%;
    width: 100%;
    padding-left: 20px;
}

.video-container {
    height: 55vh;
    position: relative;
}

.player-grid-container {
    max-width: 100%;
}

.controls {
    width: 98vh;
    display: none;
    min-height: 6vh;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    justify-content: space-between;
    background-color: #00000040;
    border-radius: 5px;
 }
 .video-canvas:hover + .controls {
     display: none;
 }

 .controls:hover {
    display: none;
}

 
.video-canvas {
    height: 100%;
    border-radius: 5px;
    width: 98vh;
}

.player-icon {
    width: 4vh;
}

.video-sub-controls {
    display: flex;
    margin-left: 20px;
}

.videosub-controls-fake {
    width: 22vh;
}

.player-live-icon {
    height: 3.5vh;
    width: 18vh;
    align-self: center;
}

.buffer {
    position: absolute;
    width: 98vh;
    height: 100%;
    top: 0;
    left: 0;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #00000040;
    border-radius: 5px;
}


@media (max-aspect-ratio: 1660/900) {
    .video-container {
        height: 45vh;
        position: relative;
    }
    .video-canvas {

        width: 80vh;
    }
    .controls {
        width: 80vh;
     }
     .buffer {
        width: 80vh;
     }
}