.custom-class div {
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 100%;
  flex-basis: 100%;
}

.custom-class div img{
  margin-left: 40px;
}

.custom-class div button{
    top: -100px;
    right: -8px;
    width: 40px;
    height: 40px;
    opacity: 0;
    position: relative;
    transition: .5s ease;
}

.MuiDropzoneSnackbar-errorAlert {
  background-color:#ff000090 !important;
}